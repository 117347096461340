.header {
  grid-template-rows: 1fr auto;
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  overflow: visible;
}

.header-header {
  z-index: 2;
  background-color: #fff;
  grid-area: 1 / 1 / 1 / 7;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  height: 57.9833px;
  display: grid;
  position: sticky;
  top: 0;
  box-shadow: 2px 2px 2px 1px #0003;
}

.header-header.fixed {
  position: fixed;
  top: 0;
}

.main-nav.fixed {
  z-index: 2;
  width: 100%;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  top: 0;
}

.header__photo {
  box-sizing: border-box;
  cursor: pointer;
  grid-area: 1 / 1 / 1 / 1;
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 1s;
}

@media only screen and (width >= 768px) and (orientation: portrait) {
  .header__photo {
    grid-area: 1 / 1 / 1 / 3;
    width: 80%;
  }
}

.header__photo:hover {
  opacity: .7;
  cursor: pointer;
  transition: all 1s;
}

.header__phone {
  box-sizing: border-box;
  text-align: right;
  color: #2f2f2f;
  width: 1fr;
  grid-area: 1 / 6 / 1 / 7;
  margin: auto;
  padding-left: 20px;
  padding-right: 5px;
  font-size: 13px;
  transition: all 1s;
}

.header__phone img {
  box-sizing: border-box;
  max-width: 100%;
}

.header__phonecall, #menu, #navcheck, label {
  display: none;
}

/*# sourceMappingURL=index.b82836a4.css.map */
