/* //////////////////////// HEADER /////////////////////////////////////////////////////////// */

.header {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr auto;
    overflow: visible;
  }
  
  .header-header {
    display: grid;
    grid-area: 1/1/1/7;
    grid-template-columns: repeat(6, 1fr);
    position: sticky;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
    height: 57.9833px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  
  .header-header.fixed {
    position: fixed;
    top: 0;
  }
  
  .main-nav.fixed {
    z-index: 2;
    position: sticky;
    top: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  
  .header__photo {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    grid-area: 1 / 1/ 1 / 1;
    transition: 1s;
    cursor: pointer;
  }
  @media only screen and (min-width:768px)and (orientation:portrait){
    .header__photo{
      grid-area: 1 / 1/ 1 / 3;
      width:80% 

    }
}
  
  .header__photo:hover {
    transition: 1s;
    opacity: 0.7;
    cursor: pointer;
  }
  
  .header__phone {
    box-sizing: border-box;
    text-align: right;
    color: #2f2f2f;
    width: 1fr;
    margin: auto;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 5px;
    transition: 1s;
    grid-area: 1 / 6 / 1 / 7;
  }
  
  .header__phone img {
    box-sizing: border-box;
    max-width: 100%;
  }

  .header__phonecall{
    display:none
  }

  #menu{
    display:none
  }

  #navcheck{
    display:none
  }

  label {
    display:none
  }